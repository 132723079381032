import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

import { pageLink } from '../utils'

import img1 from '../assets/images/placeholder.jpg';
import img2 from '../assets/images/placeholder.jpg';
import img3 from '../assets/images/placeholder.jpg';

import TelegramSvg from '../assets/svg/telegram.svg';
import TwitterSvg from '../assets/svg/twitter.svg';
import DiscordSvg from '../assets/svg/discord.svg';
import GitHubSvg from '../assets/svg/github.svg';
import MediumSvg from '../assets/svg/medium.svg';
import YoutubeSvg from '../assets/svg/youtube.svg';
import StackOverFlowSvg from '../assets/Svg/stackoverflow.svg';
import MailSvg from '../assets/Svg/mail.svg';


import './community.scss';


// DATA --------
const newsBoxesData =  [
  {
    imgSrc: img1,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    buttonText: "CTA here"
  }, {
    imgSrc: img2,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    buttonText: "CTA here"
  }, {
    imgSrc: img3,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    buttonText: "CTA here"
  }
]

const socialBoxes = [
  {
    icon: <TelegramSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Telegram",
    href: pageLink.telegram,
  },
  {
    icon: <TwitterSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Twitter",
    href: pageLink.twitter,
  },
  {
    icon: <DiscordSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Discord",
    href: pageLink.discord,
  },
  {
    icon: <GitHubSvg/> ,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Github",
    href: pageLink.github,
  },
  {
    icon: <MediumSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Medium",
    href: pageLink.medium,
  },
  {
    icon: <YoutubeSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Youtube",
    href: pageLink.youtube,
  },
  {
    icon: <StackOverFlowSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Stack Overflow",
    href: pageLink.stackoverflow,
  },
  {
    icon: <MailSvg />,
    text: 'Lorem ipsum dolor sit amet, consectetur',
    buttonText: "Email",
    href: pageLink.email,
  },
]
// END DATA


// component ----------
const SocialBox = ({icon, text, buttonText, href}) => {
  return (
    <div className="social-box">
      <div className="social-box__top-section">
        {icon}
        <p className="social-box__top-section__text">{ text }</p>
      </div>
      <a href={ href } className="social-box__link button" target="_blank" rel="noreferrer">
        { buttonText }
      </a>
    </div>
  )
}

const NewsBox = ({ imgSrc, text, buttonText }) => {
  return (
    <div className="news-box">
      <img src={imgSrc} alt="" />
      <p>{ text }</p>
      <Link className="button" to="/">{buttonText}</Link>
    </div>
  )
}


const CommunityPage = () => {
  return (
    <Layout
      title="Keymaker | Community"
      pageClassName="page-community"
    >
      <section className="page-community__hero">
        <div className="page-community__hero__container">
          <div className="page-community__hero__container--left">
            <h1 className="page-title">Keymaker Community</h1>
            <p>Our community channels are a hub for developers, token holders and supporters to stay connected and informed.</p>
          </div>
        </div>
      </section>
      <section className="page-community__connect">
        <section className="page-community__connect__container">
          <h1 className="title">Connect with Keymaker</h1>
          <p className="title-tag">We post to these channels regularly and will continue to diversify our community building efforts as we grow.</p>
          <div className="page-community__connect__container__social-boxes">
            {socialBoxes.map(socialBox => (
              <SocialBox 
                icon={socialBox.icon}
                text={socialBox.text}
                buttonText={socialBox.buttonText}
                href={socialBox.href}
              />
            ))}
          </div>
        </section>
      </section>
      <section className="page-community__news">
        <div className="page-community__news__container">
          <h1 className="title">News</h1>
          <p className="title-tag">Hear from our CEO and industry peers, as well as all of the latest Keymaker media coverage.</p>
          <div className="page-community__news__container__news-boxes">
            {newsBoxesData.map(newsBox => (
              <NewsBox
                imgSrc={newsBox.imgSrc}
                text={newsBox.text}
                buttonText={newsBox.buttonText}
              /> 
            ))}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default CommunityPage
